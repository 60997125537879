import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="login-container">
        <img src="/form-logo.svg" alt="Logo" className="logo" />
        
        <h1>Quotex Affiliate Center</h1>
        <p className="subtitle">Enter your details to login to your account</p>
        
        <form className="login-form">
          <div className="form-group">
            <input type="email" placeholder="Email" className="form-input" />
          </div>
          
          <div className="form-group">
            <input type="password" placeholder="Password" className="form-input" />
          </div>
          
          <div className="form-options">
            <div className="remember-me">
              <input type="checkbox" id="remember" />
              <label htmlFor="remember">Remember me</label>
            </div>
            <a href="https://quotex-partner.com/?pid=180734" className="forgot-password">
              Forgot password?
            </a>
          </div>
          
          <button
            type="button"
            className="sign-in-button"
            onClick={() => window.location.href = "https://quotex-partner.com/?pid=180734"}
          >
            Sign In
          </button>
        </form>
        
        <p className="signup-text">
          Don't have an account yet?{' '}
          <a href="https://quotex-partner.com/?pid=180734" className="link">
            Sign Up!
          </a>
        </p>
      </div>
    </div>
  );
}

export default App;
